import React from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_USER = gql`
  query GET_USER($email: String!) {
    users(where: { email: { _eq: $email } }) {
      name
      email
      given_name
      family_name
      id
      uid
    }
  }
`;

export default function Experiences({ authState }) {
  console.log(authState);
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { email: authState.user.email },
    context: { headers: { Authorization: `Bearer ${authState?.token}` } },
  });
  if (!authState?.token) {
    return <p>Login</p>;
  }
  if (loading) return <p>Loading all experiences to demo...</p>;
  console.log(error);
  if (error) return <p>Error :(</p>;
  if (data) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <h3>Database Info for User</h3>
        <p>{JSON.stringify(data.users[0], null, 4)}</p>
      </div>
    );
  }
  return 'no data';
}
