import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import React, { useState, useEffect } from 'react';
import UserInfo from '../components/UserInfo';

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

// Find these options in your Firebase console
firebase.initializeApp({
  apiKey: 'AIzaSyB8HEsSeX7IbUaq4yDZ7hm4pTXV9NoOk6o',
  authDomain: 'regal-oasis-253117.firebaseapp.com',
  databaseURL: 'https://regal-oasis-253117.firebaseio.com',
  projectId: 'regal-oasis-253117',
  storageBucket: 'regal-oasis-253117.appspot.com',
  messagingSenderId: '408643212634',
});

export default function Auth() {
  const [authState, setAuthState] = useState({ status: 'loading' });

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims['https://hasura.io/jwt/claims'];

        if (hasuraClaim) {
          setAuthState({ status: 'in', user, token });
        } else {
          // Check if refresh is required.
          const metadataRef = firebase
            .database()
            .ref('metadata/' + user.uid + '/refreshTime');

          metadataRef.on('value', async data => {
            if (!data.exists) return;
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            setAuthState({ status: 'in', user, token });
          });
        }
      } else {
        setAuthState({ status: 'out' });
      }
    });
  }, []);

  const signInWithGoogle = async () => {
    try {
      await firebase.auth().signInWithPopup(googleProvider);
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithFacebook = async () => {
    try {
      await firebase.auth().signInWithPopup(facebookProvider);
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = async () => {
    try {
      setAuthState({ status: 'loading' });
      await firebase.auth().signOut();
      setAuthState({ status: 'out' });
    } catch (error) {
      console.log(error);
    }
  };

  let content;
  if (authState.status === 'loading') {
    content = null;
  } else {
    content = (
      <>
        <div>
          {authState.status === 'in' ? (
            <div>
              <h2>Welcome, {authState.user.displayName}</h2>
              <button onClick={signOut}>Sign out</button>
              <UserInfo authState={authState} />
            </div>
          ) : (
            <>
              <button onClick={signInWithGoogle}>Sign in with Google</button>
              <button disabled onClick={signInWithFacebook}>
                Sign in with Facebook (disabled)
              </button>
            </>
          )}
        </div>
      </>
    );
  }

  return <div className="auth">{content}</div>;
}
